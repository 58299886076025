import {defineStore} from 'pinia'
import {useApiRequestStore} from '../../ApiRequestStore/useApiRequestStore'
import {
  IGetDatabaseRequestInterfaces,
} from '@toolify/server/src/controllers/DatabaseController/validators/GetDatabase/types/IGetDatabaseRequestInterfaces'
import {
  IGetDatabasesRequestInterfaces,
} from '@toolify/server/src/controllers/DatabaseController/validators/GetDatabases/types/IGetDatabasesRequestInterfaces'
import {
  ICreateDatabaseRequestInterfaces,
} from '@toolify/server/src/controllers/DatabaseController/validators/CreateDatabase/types/ICreateDatabaseRequestInterfaces'
import {
  IUpdateDatabaseRequestInterfaces,
} from '@toolify/server/src/controllers/DatabaseController/validators/UpdateDatabase/types/IUpdateDatabaseRequestInterfaces'
import {
  IDeleteDatabaseRequestInterfaces,
} from '@toolify/server/src/controllers/DatabaseController/validators/DeleteDatabase/types/IDeleteDatabaseRequestInterfaces'
import {mapDatabaseResponseEntity} from '@toolify/client/src/modules/api/singletons/mapDatabaseResponseEntity'
import {DatabaseTemplateType} from '@toolify/server/src/services/DatabaseTemplateService/enum/DatabaseTemplateType'
import {
  ICreateDatabaseByTemplateRequestInterfaces,
} from '@toolify/server/src/controllers/DatabaseController/validators/CreateDatabaseByTemplate/types/ICreateDatabaseByTemplateRequestInterfaces'
import {
  ICreateDatabaseByPromptRequestInterfaces,
} from '@toolify/server/src/controllers/DatabaseController/validators/CreateDatabaseByPrompt/types/ICreateDatabaseByPromptRequestInterfaces'
import {
  IDatabaseResponseEntity,
} from '@toolify/server/src/adapters/EntityApiResponseAdapter/types/responseEntities/IDatabaseResponseEntity'
import {IFilter} from '@toolify/server/src/services/FilterService/types/IFilter'
import {
  IGenerateFilterByPromptRequestInterfaces,
} from '@toolify/server/src/controllers/DatabaseController/validators/GenerateFilterByPrompt/types/IGenerateFilterByPromptRequestInterfaces'
import {
  IUnarchiveDatabaseRequestInterfaces,
} from '@toolify/server/src/controllers/DatabaseController/validators/UnarchiveDatabase/types/IUnarchiveDatabaseRequestInterfaces'
import {
  IExportDatabaseRequestInterfaces,
} from '@toolify/server/src/controllers/DatabaseController/validators/ExportDatabase/types/IExportDatabaseRequestInterfaces'
import {ExportFileTypes} from '@toolify/server/src/jobs/ExportDatabaseJob/types/ExportFileTypes'
import {
  IConvertFileToSuggestedDataInterfaces,
} from '@toolify/server/src/controllers/DatabaseController/validators/ConvertFileToSuggestedData/types/IConvertFileToSuggestedDataInterfaces.js'
import {
  IImportDatabaseRequestInterfaces,
} from '@toolify/server/src/controllers/DatabaseController/validators/ImportDatabase/types/IImportDatabaseRequestInterfaces.js'
import {IImportDatabaseFile} from '@toolify/server/src/jobs/ImportDatabaseFromFileJob/types/IImportDatabaseFile.js'

export const useDatabaseApiStore = defineStore('databaseApi', {
  actions: {
    async createDatabase(workspaceId: string, title: string, resourceGroupId: string = null): Promise<ICreateDatabaseRequestInterfaces['response']> {
      const apiRequestStore = useApiRequestStore()
      const {database} = await apiRequestStore.post<ICreateDatabaseRequestInterfaces>(`/workspace/${workspaceId}/database`, {
        data: {
          title: title,
          resourceGroupId,
        },
      })
      return {
        database: mapDatabaseResponseEntity(database),
      }
    },

    async createDatabaseByTemplate(workspaceId: string, title: string, templateType: DatabaseTemplateType): Promise<void> {
      const apiRequestStore = useApiRequestStore()
      await apiRequestStore.post<ICreateDatabaseByTemplateRequestInterfaces>(`/workspace/${workspaceId}/database-by-template`, {
        data: {
          title: title || (new Date).toLocaleString(),
          templateType,
        },
      })
    },

    async createDatabaseByPrompt(workspaceId: string, title: string, prompt: string): Promise<IDatabaseResponseEntity[]> {
      const apiRequestStore = useApiRequestStore()
      const res = await apiRequestStore.post<ICreateDatabaseByPromptRequestInterfaces>(`/workspace/${workspaceId}/database-by-prompt`, {
        data: {
          title: title || (new Date).toLocaleString(),
          prompt,
        },
      })
      return res.databases.map(database => {
        return mapDatabaseResponseEntity(database)
      })
    },

    async convertFileToUniqueSuggestedData(databaseId: string, file: File): Promise<IConvertFileToSuggestedDataInterfaces['response']> {
      const formData = new FormData()

      // Sprawdź typ MIME pliku
      if (file.type === 'text/csv' || file.type === 'application/csv') {
        // Jeśli plik to CSV
        formData.append('file', file, 'file.csv')
      } else if (file.type === 'application/json') {
        // Jeśli plik to JSON
        formData.append('file', file, 'file.json')
      } else {
        // Domyślnie dodaj plik bez zmiany nazwy
        formData.append('file', file)
      }

      formData.append('existingDatabaseId', databaseId)

      const apiRequestStore = useApiRequestStore() // Przyjmując, że to hook do zarządzania zapytaniami API

      return await apiRequestStore.post<IConvertFileToSuggestedDataInterfaces>('/database/convert-file-to-data', {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        data: formData,
      })
    },

    async importDatabase(databaseId: string, fileId: string, convertedFileData: IImportDatabaseFile[]): Promise<IImportDatabaseRequestInterfaces['response']> {
      const apiRequestStore = useApiRequestStore()
      return await apiRequestStore.post<IImportDatabaseRequestInterfaces>(`/database/${databaseId}/import`, {
        data: {
          convertedFileData,
          fileId,
        },
      })
    },

    async getDatabase(databaseId: string): Promise<IGetDatabaseRequestInterfaces['response']> {
      const apiRequestStore = useApiRequestStore()
      const {database} = await apiRequestStore.get<IGetDatabaseRequestInterfaces>(`/database/${databaseId}`)
      return {
        database: mapDatabaseResponseEntity(database),
      }
    },

    async updateDatabase(databaseId: string, database: IUpdateDatabaseRequestInterfaces['body']): Promise<void> {
      const apiRequestStore = useApiRequestStore()
      await apiRequestStore.put<IUpdateDatabaseRequestInterfaces>(`/database/${databaseId}`, {
        data: database,
      })
    },

    async deleteDatabase(databaseId: string): Promise<void> {
      const apiRequestStore = useApiRequestStore()
      await apiRequestStore.delete<IDeleteDatabaseRequestInterfaces>(`/database/${databaseId}`)
    },

    async getDatabases(workspaceId: string): Promise<IGetDatabasesRequestInterfaces['response']> {
      const apiRequestStore = useApiRequestStore()
      const {databases} = await apiRequestStore.get<IGetDatabasesRequestInterfaces>(`/workspace/${workspaceId}/databases`)
      const mappedDatabases = databases.map(database => {
        return mapDatabaseResponseEntity(database)
      })
      return {
        databases: mappedDatabases,
      }
    },

    async generateFilterByPrompt(databaseId: string, prompt: string): Promise<IFilter> {
      const apiRequestStore = useApiRequestStore()
      const {filter} = await apiRequestStore.post<IGenerateFilterByPromptRequestInterfaces>(`/database/${databaseId}/filter-by-prompt`, {
        data: {
          prompt,
        },
      })
      return filter
    },

    async unarchiveDatabase(databaseId: string): Promise<void> {
      const apiRequestStore = useApiRequestStore()
      await apiRequestStore.post<IUnarchiveDatabaseRequestInterfaces>(`/database/${databaseId}/unarchive`)
    },

    async exportDatabase(databaseId: string, exportType: ExportFileTypes, filterQuery: IFilter): Promise<void> {
      const apiRequestStore = useApiRequestStore()
      await apiRequestStore.post<IExportDatabaseRequestInterfaces>(`/database/${databaseId}/exportDatabase`, {
        data: {
          exportType,
          filter: filterQuery,
        },
      })
    },
  },
})
