<script setup lang="ts">
import {onBeforeUnmount, watch} from 'vue'
import {$, $$, $computed, $ref} from 'vue/macros'
import PageLoadingBar from '@toolify/client/src/modules/ui/components/PageLoadingBar.vue'
import {useApiRequestStore} from '@toolify/client/src/stores/ApiRequestStore/useApiRequestStore'
import AppStoppedAlertComponent from '@toolify/client/src/modules/layout/components/AppStoppedAlertComponent.vue'
import {useI18n} from 'vue-i18n'
import {UserSettingName} from '@toolify/server/src/models/mongoose/UserModel/enum/UserSettingName'
import {useAuthStore} from '@toolify/client/src/stores/AuthStore/useAuthStore'
import {
  LocaleType,
} from '@toolify/server/src/services/UserSettingsService/strategies/LocaleUserSettingStrategy/enum/LocaleType'
import {useAppStore} from '@toolify/client/src/stores/AppStore/useAppStore'
import InitialLoadingScreenComponent from '@toolify/client/src/modules/layout/components/InitialLoadingScreenComponent.vue'
import {useWorkspaceSocketHooks} from '@toolify/client/src/modules/workspace/composables/useWorkspaceSocketHooks'

useWorkspaceSocketHooks()

const appStore = useAppStore()
const apiRequestStore = useApiRequestStore()
const authStore = useAuthStore()

let {locale: i18nLocale} = $(useI18n())

onBeforeUnmount(() => {
  appStore.disposeApp()
})

let initialLoadingScreenTimeoutPassed = $ref(false)
setTimeout(() => {
  initialLoadingScreenTimeoutPassed = true
}, 1000)

const hasLoadingFinished = $computed(() => {
  return initialLoadingScreenTimeoutPassed && appStore.isFirstRouteInitialized
})


const appStoppedAlert = $computed(() => {
  return hasLoadingFinished ? `${ENV.AppBrandingName} is offline. Reconnecting...` : 'App is loading longer than usual...'
})

const currentUserLocale = $computed<LocaleType>(() => {
  return authStore.currentUser?.settings[UserSettingName.Locale]
})
watch($$(currentUserLocale), () => {
  if(!currentUserLocale) {
    return
  }
  i18nLocale = currentUserLocale as string
})
</script>

<template>
  <Transition name="loading-screen">
    <InitialLoadingScreenComponent v-if="!hasLoadingFinished"/>
  </Transition>
  <div class="app-content" :class="{'is-loading': appStore.shouldShowPageLoadingBar || apiRequestStore.hasAnyItemFailed}">
    <Transition name="app-stopped-alert">
      <AppStoppedAlertComponent v-if="apiRequestStore.hasAnyItemFailed" :text="appStoppedAlert"/>
    </Transition>
    <RouterView v-if="hasLoadingFinished" v-slot="{Component: currentComponent, route}">
      <Transition :name="route.meta.transitionName.toString()" appear class="page-transition-position">
        <Component :is="currentComponent"/>
      </Transition>
    </RouterView>
    <PageLoadingBar v-if="appStore.shouldShowPageLoadingBar && hasLoadingFinished"/>
  </div>
</template>

<style scoped lang="scss">
.app-content {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;

  &.is-loading {
    opacity: 0.85;
    transition: all .1s ease-in;
    pointer-events: none;
  }
}

.page-transition-position {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  overflow: hidden;
}

.depth-transition-100-to-200 {
  &-enter-active,
  &-leave-active {
    transition: all 0.5s ease;
  }
  &-enter-from {
    opacity: 0;
    transform: scale(1.2);
  }
  &-leave-to {
    opacity: 0;
    transform: scale(0.8);
  }
}

.depth-transition-500-to-200 {
  &-enter-active {
    transition: all 0.5s ease;
  }
  &-leave-active {
    transition: all 0.25s ease;
  }
  &-enter-from {
    transform: scale(0.8);
    opacity: 0;
  }
  &-leave-to {
    transform: scale(1.1);
    opacity: 0;
  }
}

.depth-transition-200-to-500 {
  &-enter-active {
    transition: all 0.5s ease;
  }
  &-leave-active {
    transition: all 0.25s ease;
  }
  &-enter-from {
    transform: scale(1.2);
    opacity: 0;
  }
  &-leave-to {
    transform: scale(0.9);
    opacity: 0;
  }
}

.depth-transition-200-to-100, {
  &-enter-active,
  &-leave-active {
    transition: all 0.5s ease;
    transition-delay: 0.25s;
  }
  &-enter-from {
    opacity: 0;
    transform: scale(0.85);
  }
  &-leave-to {
    opacity: 0;
    transform: scale(1.1);
  }
}

.depth-transition-100-to-150, .depth-transition-200-to-150 {
  &-enter-active {
    transition: all .3s cubic-bezier(0, 0.5, 0, 1);
    top: 0;
    left: 0;
  }
  &-leave-active {
    transition: all 0.3s cubic-bezier(0.25, 0.75, 0.25, 1);
  }
  &-enter-from {
    transform: translateY(100%);
    opacity: 0;
  }
  &-leave-to {
    opacity: 0;
    transform: scale(0.85);
  }
}



.depth-transition-150-to-100, .depth-transition-150-to-200 {
  &-enter-active {
    transition: all 0.3s cubic-bezier(0.25, 0.75, 0.25, 1);
    transition-delay: .1s;
  }
  &-leave-active {
    transition: all 0.3s cubic-bezier(0.25, 0.75, 0.25, 1);
    top: 0;
    left: 0;
  }
  &-enter-from {
    opacity: 0;
    transform: scale(0.9);
    border-radius: 10px;
    overflow: hidden;
  }
  &-leave-to {
    transform: translateY(50%);
    opacity: 0;
  }
}

.initial-transition {
  &-enter-active,
  &-leave-active {
    transition: all 0.5s ease;
    transition-delay: 0.25s;
  }
  &-enter-from,
  &-leave-to {
    opacity: 0;
    transform: scale(0.8);
  }
}

.loading-screen {
  &-enter-active,
  &-leave-active {
    transition: all 0.5s ease;
  }
  &-enter-from,
  &-leave-to {
    opacity: 0;
    transform: scale(1.5);
  }
}

.app-stopped-alert {

  &-enter-active {
    transition: all .2s cubic-bezier(0, 0.5, 0, 1.5) .1s;
  }
  &-leave-active {
    transition: all .2s cubic-bezier(0.25, 0.75, 0.25, 1);
  }

  &-enter-active,
  &-leave-active {
    transform: translateY(0);
  }
  &-enter-from,
  &-leave-to {
    opacity: 0;
    transform: translateY(100%);
  }
}
</style>
